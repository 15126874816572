/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import {
    faArrowLeft,
    faAsterisk,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updatePassword } from 'firebase/auth';
import { captureException, captureFeedback, withScope } from '@sentry/react';
import { Button, Input, Page } from '../../../components/common.tsx';
import LinkTile from '../../../components/LinkTile.tsx';
import { routeNames } from '../../../navigation/types.navigation.ts';
import { useAuth } from '../../../contexts/AuthContext.tsx';
import { deleteAccount } from './SettingsPage.helpers.ts';
import LoadingOverlay from '../../../components/LoadingOverlay.tsx';

export function SettingsPage() {
    const navigate = useNavigate();
    const { user, userData, fetchUserData, logout } = useAuth();
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [showChangePassword, setShowChangePassword] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
    const [reason, setReason] = useState<{ reason: string; message: string }>();

    React.useEffect(() => {
        fetchUserData();
    }, []);

    async function handleDeleteAccount() {
        setLoading(true);
        try {
            await deleteAccount();
            withScope((scope) => {
                scope.setExtra('action', 'feedback_delete');
                scope.setExtra('reason', reason?.reason || '');
                captureFeedback({
                    message: reason?.message || '',
                });
            });
            await logout();
            navigate(routeNames.login);
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', userData?.id);
                scope.setExtra('action', 'delete_account');
                captureException(e);
            });
        }
        setDeleteErrorMessage(true);
        setLoading(false);
    }

    return (
        <Page>
            <LoadingOverlay isLoading={loading} />
            <div className="flex pb-2">
                <Link
                    to=".."
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }}
                    className="mr-2"
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </Link>
                <h1>Manage Account</h1>
            </div>
            <h4>Contact Infomation</h4>
            <div className="pb-2">
                <label>Email</label>
                <p>{user?.email}</p>
            </div>
            <div className="pt-2">
                <LinkTile
                    text="Delete account"
                    icon={faTrash}
                    onClick={() => {
                        setShowDeleteModal(true);
                    }}
                />
            </div>
            <div className="pt-2">
                <LinkTile
                    text="Change password"
                    icon={faAsterisk}
                    onClick={() => {
                        setShowChangePassword(true);
                    }}
                />
            </div>
            {showDeleteModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 m-4 rounded md:w-6/12 text-center">
                        <h2 className="pb-2">
                            Are you sure you want to delete your account?
                        </h2>

                        <h3 className="pb-2">
                            On account deletion you will lose your life time
                            access.
                        </h3>
                        <DeleteReason
                            setReason={(x, y) =>
                                setReason({ message: y, reason: x })
                            }
                        />
                        {deleteErrorMessage && (
                            <p className="text-red-500">
                                Error : Failed to delete account, please try
                                again. If error persits please contact
                                support@early-drive.com
                            </p>
                        )}
                        <div>
                            <div className="pb-2">
                                <Button
                                    disabled={!reason}
                                    onClick={() => handleDeleteAccount()}
                                >
                                    Delete
                                </Button>
                            </div>
                            <Button
                                onClick={() => {
                                    setShowDeleteModal(false);
                                    setDeleteErrorMessage(false);
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {showChangePassword && (
                <ChangePasswordModal
                    onClose={() => {
                        setShowChangePassword(false);
                    }}
                />
            )}
        </Page>
    );
}

function ChangePasswordModal(props: { onClose: () => void }) {
    const { user } = useAuth();
    const { onClose } = props;
    const [loading, setLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
    const [error, setError] = React.useState('');

    async function onPasswordChange() {
        if (password !== passwordConfirmation) {
            setError('Passwords do not match');
            return;
        }
        setLoading(true);
        try {
            // Call API to change password
            if (user) {
                await updatePassword(user, password);
                onClose();
                alert('Password changed successfully');
            }
        } catch (e) {
            setError(e.message);
            withScope((scope) => {
                scope.setExtra('uid', user?.uid);
                scope.setExtra('action', 'change_password');
                captureException(e);
            });
        }
        setLoading(false);
    }
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <LoadingOverlay isLoading={loading} />
            <div className="bg-white p-4 m-4 rounded md:w-6/12 text-center">
                <h2 className="pb-2">Change your password</h2>
                {error && <p className="text-red-500">{error}</p>}
                <div>
                    <label>New Password</label>
                    <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="pb-2">
                    <label>Confirm Password</label>
                    <Input
                        type="password"
                        value={passwordConfirmation}
                        onChange={(e) =>
                            setPasswordConfirmation(e.target.value)
                        }
                    />
                </div>
                <div>
                    <div className="pb-2">
                        <Button onClick={() => onPasswordChange()}>
                            Submit
                        </Button>
                    </div>
                    <Button onClick={onClose}>Close</Button>
                </div>
            </div>
        </div>
    );
}

function DeleteReason(props: {
    setReason: (reason: string, message: string) => void;
}) {
    const { setReason } = props;
    // List of predefined reasons
    const reasons = [
        'Too Expensive',
        'Not Enough Content',
        'Not finding value',
        'Technical issues',
        'Other',
    ];

    const [selectedReason, setSelectedReason] = useState('');
    const [customReason, setCustomReason] = useState('');

    return (
        <form className="p-4 bg-white shadow-md rounded-md text-left">
            <h2 className="text-lg font-semibold mb-4">Reason?</h2>
            {reasons.map((reason) => (
                <label key={reason} className="block mb-2 cursor-pointer">
                    <input
                        type="radio"
                        name="deleteReason"
                        value={reason}
                        checked={selectedReason === reason}
                        onChange={() => {
                            setSelectedReason(reason);
                            setReason(reason, customReason);
                        }}
                        className="mr-2"
                    />
                    {reason}
                </label>
            ))}

            <input
                type="text"
                placeholder="Please specify"
                value={customReason}
                onChange={(e) => {
                    setCustomReason(e.target.value);
                    setReason(selectedReason, e.target.value);
                }}
                className="w-full p-2 border rounded-md mb-4"
            />
        </form>
    );
}
