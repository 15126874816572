import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import {
    PayPalScriptProvider,
    ReactPayPalScriptOptions,
} from '@paypal/react-paypal-js';
import * as Sentry from '@sentry/react';
import router from './navigation/Router.tsx';
import { AuthProvider } from './contexts/AuthContext.tsx';
import { ModalProvider } from './contexts/ModalContext.tsx';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary.tsx';

Sentry.init({
    dsn: 'https://faa8ff7a0c4910b61b45d0b17f5b78e0@o4508198025691136.ingest.us.sentry.io/4508198027001856',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ['https://dashboard-app.com.au'],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const initialOptions: ReactPayPalScriptOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT || '',
    vault: true,
    currency: 'AUD',
    intent: 'capture',
};

function App() {
    return (
        <ErrorBoundary>
            <ModalProvider>
                <PayPalScriptProvider options={initialOptions}>
                    <AuthProvider>
                        <RouterProvider router={router} />
                    </AuthProvider>
                </PayPalScriptProvider>
            </ModalProvider>
        </ErrorBoundary>
    );
}

export default App;
