import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext.tsx';
import { routeNames } from '../navigation/types.navigation.ts';
import { Button } from '../components/common.tsx';

export default function Page404() {
    const { user } = useAuth();
    const navigate = useNavigate();
    return (
        <div className="h-full w-full justify-center items-center text-center flex flex-col p-5">
            <h1 className="pb-2 text-7xl text-button-blue">OOPS</h1>
            <p className="pb-2 text-2xl">
                Seems you are lost, there is no content here
            </p>
            {user ? (
                <Button onClick={() => navigate(routeNames.dashboard)}>
                    Go to Dashboard{' '}
                </Button>
            ) : (
                <Button onClick={() => navigate(routeNames.login)}>
                    Go to Login
                </Button>
            )}
        </div>
    );
}
