import React, { useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { Button } from '../../components/common.tsx';
import { emails, UnAuthSidePanel } from './SignupPage.tsx';
// import GoogleButton from '../../components/GoogleSignInButton.tsx';

const moreAustralianDrivingFacts = [
    {
        title: 'Longest Highway in the World',
        info: "Australia's Highway 1 is the longest national highway in the world, circling the entire continent for over 14,500 kilometers!",
    },
    {
        title: 'The Nullarbor Plain',
        info: 'The Nullarbor Plain, crossing South Australia and Western Australia, has the longest straight stretch of road in the world, spanning 146.6 kilometers without a single bend.',
    },
    {
        title: 'Kangaroo Road Signs',
        info: 'Kangaroo warning signs are common on Australian roads, especially in rural areas. Hitting a kangaroo can cause serious damage to vehicles, so drivers are advised to take caution.',
    },
    {
        title: "The 'Ute' Culture",
        info: "Australia has a unique vehicle culture centered around the 'ute' (utility vehicle), a type of pickup truck. Utes are popular in rural areas and have become an iconic Australian vehicle.",
    },
    {
        title: 'Unique Roadside Attractions',
        info: 'Australia is home to some quirky roadside attractions, like the Big Pineapple, the Giant Koala, and the Big Banana, all visible on popular driving routes.',
    },
    {
        title: 'Camels in the Outback',
        info: 'Australia has the largest population of wild camels in the world. They were originally brought for transport in the Outback and are often seen crossing roads in desert regions.',
    },
    {
        title: 'Hook Turns in Melbourne',
        info: "Melbourne has a unique 'hook turn' rule for turning right at certain intersections. Drivers must pull into the far left lane to complete a right turn when safe, allowing trams to pass.",
    },
    {
        title: 'Highest Road in Australia',
        info: 'The highest road in Australia is the Charlotte Pass Road in New South Wales, which reaches an altitude of 1,837 meters in the Snowy Mountains.',
    },
    {
        title: "One of the World's Largest Parking Lots",
        info: "Australia's Perth Airport has one of the largest airport parking lots in the Southern Hemisphere, covering around 190 hectares with over 21,000 parking spaces.",
    },
    {
        title: 'Driving in Sand Dunes',
        info: "Australia has many popular sand dune driving spots, like Stockton Beach in New South Wales. Sand driving requires special skills, and it's a favorite pastime for 4WD enthusiasts.",
    },
    {
        title: 'Beaches with Legal Driving',
        info: "In certain areas of Australia, such as Rainbow Beach in Queensland, it's legal to drive directly on the sand, making beach driving a unique experience.",
    },
    {
        title: "Outback 'Self-Serve' Fuel Stations",
        info: 'Some fuel stations in the Outback are entirely self-service, often relying on honesty boxes or credit card readers due to the remoteness and low traffic.',
    },
    {
        title: 'Biggest Road Train Ever',
        info: 'Australia set the world record for the longest road train, with 112 semi-trailers attached to a single truck, spanning over 1,474 meters!',
    },
    {
        title: 'Alcohol Interlocks for Offenders',
        info: 'Certain Australian states require repeat DUI offenders to install alcohol interlock devices in their vehicles, which prevent the car from starting if alcohol is detected.',
    },
    {
        title: 'Remote Ambulance Planes',
        info: "In very remote areas, the Royal Flying Doctor Service serves as an 'air ambulance,' as regular ambulances can't reach certain parts of the Outback quickly enough.",
    },
    {
        title: "Merging Traffic 'Zipper Rule'",
        info: "Australia encourages the 'zipper rule' for merging traffic, where cars alternate turns to merge smoothly. This is especially common in high-traffic areas.",
    },
    {
        title: 'Highway Rest Stops with BBQs',
        info: 'Many rest stops along Australian highways come equipped with free public barbecues, making it easy for drivers to cook a meal on the road.',
    },
    {
        title: "Australia's 'Big Things' Tourism",
        info: 'Drivers along Australian roads can enjoy dozens of oversized roadside attractions, from the Big Mango to the Big Lobster, which have become popular tourist stops.',
    },
    {
        title: 'Driving Tests Vary by State',
        info: 'Each Australian state has slightly different driving test requirements, so drivers moving between states often need to adjust to new rules and tests.',
    },
    {
        title: 'Wild Horse Warnings',
        info: "In Outback Queensland and Northern Territory, drivers may encounter wild horses, or 'brumbies,' crossing the road—a unique hazard to Australian roads.",
    },
];

// Function to randomly select one fact
function getRandomMumFact() {
    const randomIndex = Math.floor(
        Math.random() * moreAustralianDrivingFacts.length
    );
    return moreAustralianDrivingFacts[randomIndex];
}

function routeAwayHandler(email: string, route: string): string {
    const searchParams = new URL(`${window.location.origin}${route}`);
    searchParams.searchParams.append('email', email);
    return searchParams.href;
}

export function LoginPage() {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        try {
            await login(email, password);
            navigate(routeNames.dashboard);
        } catch (err) {
            setError('Failed to log in');
        }
        setLoading(false);
    };

    const emailIndex = useRef(Math.floor(Math.random() * emails.length));
    const randomfact = useRef(getRandomMumFact());

    return (
        <div className="flex w-full h-full">
            <div className="md:w-5/12 w-full bg-gray-100  p-3 h-full">
                <div className="m-auto h-full bg-white rounded-lg shadow-lg p-4 text-center flex flex-col justify-center items-center">
                    {error && <p className="text-red-600 text-2xl">{error}</p>}
                    <p className="text-9xl text-center">🚗</p>
                    <h2 className="text-3xl mb-6">Welcome to EarlyDrive</h2>
                    <form
                        className="text-left w-10/12 mx-auto"
                        onSubmit={handleSubmit}
                    >
                        <div className="mt-2">
                            <label htmlFor="email" className="text-gray-500 ">
                                Email
                            </label>
                            <br />
                            <input
                                id="email"
                                data-testid="login-email-id"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="email"
                                placeholder={emails[emailIndex.current]}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                            />
                        </div>

                        <div className="mt-2 mb-4">
                            <label
                                htmlFor="password"
                                className="text-gray-500 "
                            >
                                Password
                            </label>
                            <br />
                            <input
                                id="password"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50 "
                                type="password"
                                placeholder="******"
                                value={password}
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Link
                                to={routeAwayHandler(email, routeNames.forgot)}
                                className="text-secondary underline font-bold"
                            >
                                Forgot your password?
                            </Link>
                        </div>

                        <Button disabled={loading} type="submit">
                            {loading ? 'Loading...' : 'Login'}
                        </Button>

                        <p className="text-center    pt-4 ">
                            Don&apos;t have an account?{' '}
                            <Link
                                to={routeAwayHandler(email, routeNames.signup)}
                                className="text-secondary underline font-bold"
                            >
                                Signup here
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
            <UnAuthSidePanel
                text1={randomfact.current.title}
                text2=""
                text3={randomfact.current.info}
            />
        </div>
    );
}
