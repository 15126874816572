import React, { useEffect, useState } from 'react';
import { captureException, withScope } from '@sentry/react';
import { useAuth } from '../../contexts/AuthContext.tsx';

import { Button, Input, Page } from '../../components/common.tsx';
import { setUserDataBookingDate } from '../../queries/users.query.ts';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';

export function DashboardPage() {
    const { getDrivesTestDate, userData, fetchUserData } = useAuth();
    const [successMesage, setSuccessMessage] = useState<null | string>(null);
    const [currentDriversTestData, setCurrentDriversTestDate] =
        useState(getDrivesTestDate());
    useEffect(() => {
        setCurrentDriversTestDate(getDrivesTestDate());
    }, [userData]);

    const [loading, setLoading] = useState(false);
    async function onSaveTestDate() {
        setLoading(true);
        try {
            if (userData) {
                await setUserDataBookingDate(
                    userData.id,
                    Math.floor(
                        new Date(currentDriversTestData).getTime() / 1000
                    )
                );
                setSuccessMessage('Driving Test Date Updated');
                await fetchUserData();
            }
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', userData?.id);
                scope.setExtra('action', 'dashboard_save_date');
                captureException(e);
            });
        }
        setLoading(false);
    }
    return (
        <Page>
            <LoadingOverlay isLoading={loading} />
            <h1 className="pb-2">Driving Test Date</h1>
            <div>
                <label className="text-left pb-2 self-start">
                    Current Booking Date
                </label>
                <p className="pb-2">
                    Keeping this up to date will make sure you only receive
                    driving test availabilty dates that are closer than your
                    current booked date
                </p>
                <Input
                    type="date"
                    value={currentDriversTestData}
                    onChange={(e) => setCurrentDriversTestDate(e.target.value)}
                />
                <div className="pt-4">
                    <Button onClick={() => onSaveTestDate()}>Save</Button>
                </div>
            </div>

            <p className="text-green-600 pt-4 text-xl">{successMesage}</p>
        </Page>
    );
}
