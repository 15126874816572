import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    PropsWithChildren,
    useMemo,
    useCallback,
} from 'react';
import {
    User,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    UserCredential,
} from 'firebase/auth';

import Lottie from 'react-lottie';
import auth from '../firebase/config.firbase.ts';
import {
    createUserData,
    getUserData,
    setUserDataHasOnboarded,
    UserData,
} from '../queries/users.query.ts';

import loadingAnimation from '../components/lotties/loading.json';

interface AuthContextType {
    user: User | null;
    userData?: UserData;
    completeOnboardHandler: () => void;
    login: (email: string, password: string) => Promise<UserCredential>;
    signup: (
        email: string,
        password: string,
        mailingList: boolean
    ) => Promise<UserCredential>;
    logout: () => Promise<void>;
    fetchUserData: () => Promise<void>;
    getDrivesTestDate: () => string;
    getLocations: () => string[];
}

function LottieLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <Lottie options={defaultOptions} height={200} width={300} />;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export function AuthProvider({ children }: PropsWithChildren) {
    const [user, setUser] = useState<User | null>(null);
    const [userData, setUserData] = useState<UserData>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false); // Set loading to false once the auth state is determined
        });
        return unsubscribe; // Cleanup subscription on unmount
    }, []);

    const login = useCallback((email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);
    }, []);

    const signup = useCallback(
        async (email: string, password: string, mailingList: boolean) => {
            const response = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );

            await createUserData(response.user.uid, email);
            return response;
        },
        []
    );

    const logout = useCallback(() => {
        return signOut(auth);
    }, []);

    useEffect(() => {
        if (user) {
            getUserData().then((response) => {
                setUserData(response);
            });
        }
    }, [user]);

    async function completeOnboardHandler() {
        if (user?.uid && userData?.id) {
            await setUserDataHasOnboarded(user?.uid);
            setUserData({ ...userData, isOnboarded: true });
        }
    }

    async function fetchUserData() {
        if (user) {
            const data = await getUserData();
            setUserData(data);
        }
    }

    function getDrivesTestDate(): string {
        if (userData?.currentBookingDate) {
            const formattedDate = formatDate(
                new Date(userData.currentBookingDate * 1000).toDateString()
            );
            return formattedDate;
        }

        return '';
    }

    function getLocations(): string[] {
        return userData?.centres || [];
    }

    const value = useMemo(
        () => ({
            user,
            login,
            signup,
            logout,
            userData,
            completeOnboardHandler,
            fetchUserData,
            getDrivesTestDate,
            getLocations,
        }),
        [
            user,
            login,
            signup,
            logout,
            userData,
            completeOnboardHandler,
            fetchUserData,
            getDrivesTestDate,
            getLocations,
        ]
    );

    return (
        <AuthContext.Provider value={value}>
            {loading ? (
                <div className="h-full flex justify-center items-center w-full flex-col">
                    <LottieLoader /> <h1>Loading</h1>
                </div>
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
}

export function formatDate(dateString: string): string {
    // Parse the date string
    const date = new Date(dateString);
    // Extract day, month, and year and format to dd-mm-yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}
