/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FirebaseError } from 'firebase/app';
import { useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { Button, Input, Page } from '../../components/common.tsx';
// import GoogleButton from '../../components/GoogleSignInButton.tsx';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { toLoginLinkHandler } from './ForgotPasswordPage.tsx';

export const emails = [
    'hot_girls_hit_curbs@gmail.com',
    'turbocharged@zoommail.com',
    'revvedup@pitstop.com',
    'exhausted@fullthrottle.com',
    'carguy4life@burnrubber.com',
    'manualshift@gearhead.com',
    'needforspeed@racerboy.com',
    'v8power@musclecar.com',
    'burningrubber@fastlane.com',
    'driftking@sidewaysmail.com',
    'goodyearjunkie@tiretrack.com',
    'nitrousboost@speedster.com',
    'shiftfaster@clutchking.com',
    'gocartking@trackstar.com',
    'motorhead@revitup.com',
    'roadwarrior@topgear.com',
    'oilchange@greasejunkie.com',
    'mufflerman@tailpipes.com',
    'whiplash@dragstrip.com',
    'carburetor@fuelinjected.com',
    'fuelledbyadrenaline@gearshift.com',
    'pistonhead@greasegarage.com',
    'undercoated@rustfree.com',
    'carma@autobahn.com',
    'petrolhead@wheeldrive.com',
];

function handleFirebaseSignupError(error: FirebaseError): string {
    switch (error.code) {
        case 'auth/email-already-in-use':
            return 'This email is already associated with an account. Please try logging in or using a different email.';

        case 'auth/invalid-email':
            return 'The email address is not valid. Please check and enter a valid email.';

        case 'auth/operation-not-allowed':
            return 'Email/password sign-up is currently disabled. Please contact support.';

        case 'auth/weak-password':
            return 'Your password is too weak. Please choose a stronger password.';

        case 'auth/network-request-failed':
            return 'Network error. Please check your internet connection and try again.';

        case 'auth/too-many-requests':
            return 'Too many attempts. Please wait a moment before trying again.';

        default:
            return 'An unknown error occurred. Please try again later.';
    }
}

function SignupPage() {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [password, setPassword] = useState('');
    const { signup } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [agreeTerms, setAgreeTerms] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    useEffect(() => {
        // Get the 'subscription' query parameter from the URL
        const subscription = searchParams.get('subscription');
        if (subscription) {
            // Store the subscription code in localStorage or context for later use
            localStorage.setItem('subscriptionCode', subscription);
        }
    }, [searchParams]);

    const handleSubmit = async (e: React.FormEvent) => {
        if (!loading) {
            setLoading(true);
            e.preventDefault();
            try {
                await signup(email, password, newsletter);
                navigate(routeNames.subscription);
            } catch (err) {
                setError(handleFirebaseSignupError(err as FirebaseError));
            }
            setLoading(false);
        }
    };

    const emailIndex = useRef(Math.floor(Math.random() * emails.length));

    return (
        <div className="flex w-full h-full">
            <div className="md:w-5/12 w-full bg-gray-100  p-3 h-full">
                <div className="m-auto h-full bg-white rounded-lg shadow-lg p-4 text-center flex flex-col justify-center items-center">
                    {error && (
                        <p className="text-red-600 text-lg px-2">{error}</p>
                    )}
                    <p className="text-9xl text-center">🚙</p>

                    <div className=" mb-6">
                        <h2 className="text-3xl">Signup to Early Drive</h2>
                        <p className="text-sm text-gray-400">
                            After Signup you will be asked to purchase
                            EarlyDrive before you can start using it
                        </p>
                    </div>
                    <form
                        className="text-left w-10/12 mx-auto"
                        onSubmit={handleSubmit}
                    >
                        <div className="mt-2">
                            <label htmlFor="email" className="text-gray-500 ">
                                Email
                            </label>
                            <br />
                            <input
                                data-testid="signup-email-id"
                                id="email"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="email"
                                placeholder={emails[emailIndex.current]}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="email"
                                inputMode="email"
                            />
                        </div>

                        <div className="my-2">
                            <label
                                htmlFor="password"
                                className="text-gray-500 "
                            >
                                Password
                            </label>
                            <br />
                            <input
                                id="password"
                                required
                                className="rounded-lg border border-gray-400 p-2 w-full mt-1 bg-gray-50"
                                type="password"
                                placeholder="******"
                                value={password}
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.target.value)}
                                minLength={6}
                            />
                        </div>

                        <Button disabled={loading} type="submit">
                            {loading ? 'Loading...' : 'Signup'}
                        </Button>

                        <p className="text-center    pt-4 ">
                            Already have an account?{' '}
                            <Link
                                to={toLoginLinkHandler(email)}
                                className="text-secondary underline font-bold"
                            >
                                Login here
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
            <UnAuthSidePanel
                text1="Get a Early Test Date Now!"
                text2="Join other users skipping the que by weeks"
                text3="On Average there a 100 test cancellations a day"
            />
        </div>
    );
}

export function UnAuthSidePanel(props: {
    text1: string;
    text2: string;
    text3: string;
}) {
    const { text1, text2, text3 } = props;
    return (
        <div className="w-7/12 p-20 h-full max-md:hidden bg-secondary flex flex-col justify-end">
            <p className="text-9xl">🚗 🚗 🚗</p>
            <div>
                <h1 className="text-6xl text-white pb-4">{text1}</h1>
                <h2 className="pb-4 text-white">{text2}</h2>
                <h3 className="text-white">{text3}</h3>
            </div>
        </div>
    );
}

export default SignupPage;
