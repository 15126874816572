/* eslint-disable no-use-before-define */
/* eslint-disable prettier/prettier */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { captureException, withScope } from '@sentry/react';
import { Button, Input } from '../../components/common.tsx';

import { setUserDataBookingDate, setUserDataHasSubscribed, setUserDataLoactions } from '../../queries/users.query.ts';
import { formatDate, useAuth } from '../../contexts/AuthContext.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';

const regions = [{
    name: 'Central Queensland',
    locations: [
        { region: 'CENTRAL', id: '82165377073800', timeStamp: null },
        { region: 'CQ CALLIDE VALLEY', id: '21386922848900' },
        { region: 'CQ HIGHLANDS', id: '21386931954200' },
        { region: 'CQ MACKAY', id: '21386936723000' },
        { region: 'CQ ROCKY - GLADSTONE', id: '21386951350100' },
        { region: 'CQ WEST', id: '21386928135000' },
        { region: 'CQ WHITSUNDAYS', id: '21386973072000' },
    ]
},
{
    name: 'North Queensland',
    locations: [
        { region: 'NQ NORTH COAST', id: '21386990805500' },
        { region: 'NQ NORTH WEST', id: '21415132018500' },
        { region: 'NQ TABLELANDS', id: '21387440931800' },
        { region: 'NQ TOWNSVILLE', id: '21387449077300' },
    ]
},
{
    name: 'South East Queensland',
    locations: [
        { region: 'SEQ BRISBANE NORTHSIDE', id: '21387475861900' },
        { region: 'SEQ BRISBANE SOUTHSIDE', id: '21387484948500' },
        { region: 'SEQ GOLD COAST', id: '98545378222700' },
        { region: 'SEQ IPSWICH', id: '21387492948200' },
        { region: 'SEQ LOGAN', id: '98482785569500' },
        { region: 'SEQ SUNSHINE COAST & HINTERLAND', id: '21387516381000' },
    ]
},
{
    name: 'South Queensland',
    locations: [
        { region: 'SQ BORDER', id: '21387548373000' },
        { region: 'SQ DARLING DOWNS', id: '21387558328200' },
        { region: 'SQ KINGAROY', id: '21387561790300' },
        { region: 'SQ SOUTH WEST', id: '21387677510900' },
        { region: 'SQ WIDE BAY BURNETT', id: '21387682162100' },
    ]
}
]



type OnboardTemplate = {
    id: string
    header: string;
    description: string;
    children: ReactNode;
};

function Pill(props: {
    title: string;
    isSelected: boolean;
    onClick: () => void;
}) {
    const { isSelected, onClick, title } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className={` rounded-md w-full  ${isSelected ? 'text-white bg-action border-action-hover' : ' bg-white border-primary border'}  border font-semibold  text-left px-3 py-4 md:px-10 md:py-4`}
        >
            {title}
        </button>
    );
}

export function InterestList(props: {
    selectedInterests: string[];
    setSelectedInterests: React.Dispatch<React.SetStateAction<string[]>>;
}) {
    const { selectedInterests, setSelectedInterests } = props;

    async function onToggleInterestHandler(id: string) {
        if (selectedInterests.includes(id)) {
            const newSelectedInterests = selectedInterests.filter(
                (item) => item !== id
            );
            setSelectedInterests(newSelectedInterests);
        } else {
            const newSelectedInterests = [...selectedInterests, id];
            setSelectedInterests(newSelectedInterests);
        }
    }

    return (
        <div className="flex flex-wrap w-full">
            {regions.map((item) => (
                <div className="pb-2 pr-4">
                    <Pill
                        isSelected={selectedInterests.includes(item.name)}
                        title={item.name}
                        onClick={() => onToggleInterestHandler(item.name)}
                    />
                </div>
            ))}
        </div>
    );
}

const onboardingSlidesData: OnboardTemplate[] = [
    {
        id: 'get_started',
        header: `Let's Get Started`,
        description:
            'Welcome to Easy Drive, our mission to get you driving quicker.',
        children: (
            <div className="flex flex-1 md:flex-none justify-center items-center max-w-80 ">
                <span style={{ fontSize: 200 }}>🎉</span>
            </div>
        ),
    },
    {
        id: 'location',

        header: 'Where can you take your drivers test?',
        description:
            'Select locations where you can realistically get to for a test ',
        children: <div />,
    },
    {
        id: 'booking_slide',
        header: 'Your Current Booking Date',
        description:
            'You will only be notified with dates that are before your current Booked Test Date',
        children: (
            <div />
        ),
    },
    {
        id: 'finished',
        header: 'You are setup',
        description:
            'You will start receiving notifications to ',
        children: (
            <div className="flex flex-1 md:flex-none justify-center items-center max-w-80 ">
                <span style={{ fontSize: 200 }}>✉️</span>
            </div>
        ),
    },
];




function OnboardPage() {
    const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const { user, completeOnboardHandler, fetchUserData, userData } = useAuth();
    const navigate = useNavigate();
    const requestSent = useRef(false);
    const onboardingSlides = onboardingSlidesData;
    const initialDate = new Date(new Date().setDate(new Date().getDate() + 60)).toDateString();

    const [driversTestDate, setDriversTestDate] = useState<string>(formatDate(initialDate))

    useEffect(() => {
        if (!userData?.plan) {
            setUserDataHasSubscribed(user?.uid || '').catch((e) => {
                withScope((scope) => {
                    scope.setExtra('uid', user?.uid);
                    scope.setExtra('action', 'subscribed');
                    captureException(e);
                });
            })
        }
    }, [user])
    onboardingSlides[1].children = (
        <div className='pb-2 w-full h-full flex items-start'>

            <Accordion
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
            />
        </div>
    );

    onboardingSlides[2].children = (
        <>
            <label className='text-left pb-2 self-start'>Current Booking Date</label>
            <Input type='date' value={driversTestDate} onChange={e => setDriversTestDate(e.target.value)} />
        </>
    )

    onboardingSlides[3].description = `You will start receiving notification to ${userData?.email}`

    const onNextHandler = async () => {
        if (
            currentSlideIndex === onboardingSlides.length - 1 &&
            requestSent.current === false
        ) {
            if (user?.uid) {
                try {
                    requestSent.current = true;
                    await completeOnboardHandler();
                    await fetchUserData();
                    navigate(routeNames.dashboard);
                    requestSent.current = false;
                } catch (e) {
                    withScope((scope) => {
                        scope.setExtra('uid', user?.uid);
                        scope.setExtra('action', 'update_user_obnboard_flag');
                        captureException(e);
                    });
                }
            }
        } else if (
            user &&
            currentSlideIndex === 1 &&
            requestSent.current === false
        ) {
            requestSent.current = true;
            try {
                await setUserDataLoactions(user.uid, selectedLocations);
            } catch (e) {
                withScope((scope) => {
                    scope.setExtra('uid', user?.uid);
                    scope.setExtra('action', 'onboarding_save_interests');
                    captureException(e);
                });
            }
            setCurrentSlideIndex(currentSlideIndex + 1);
            requestSent.current = false;
        } else if (
            user &&
            requestSent.current === false &&
            onboardingSlides[currentSlideIndex].id === 'booking_slide'
        ) {
            requestSent.current = true;
            try {
                if (driversTestDate) {
                    await setUserDataBookingDate(user.uid, Math.floor(new Date(driversTestDate).getTime() / 1000));
                } else {
                    await setUserDataBookingDate(user.uid, Math.floor(new Date(formatDate(initialDate)).getTime() / 1000));

                }
            } catch (e) {
                withScope((scope) => {
                    scope.setExtra('uid', user?.uid);
                    scope.setExtra('action', 'onboarding_save_booking_date');
                    captureException(e);
                });
            }
            setCurrentSlideIndex(currentSlideIndex + 1);
            requestSent.current = false;
        }

        else {
            setCurrentSlideIndex(currentSlideIndex + 1);
        }
    };
    const ActionButtonText =
        currentSlideIndex === onboardingSlides.length - 1 ? 'Complete' : 'Next';

    function isDisabled(pageIndex: number) {
        if (pageIndex === 1 && selectedLocations.length === 0) return true;
        return false;
    }
    return (
        <div className="p-4 flex-1 flex flex-col h-full ">
            <div>
                {currentSlideIndex !== 0 && (
                    <button
                        type="button"
                        className="self-start max-md:hidden "
                        onClick={() => {
                            setCurrentSlideIndex(currentSlideIndex - 1);
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="text-2xl"
                        />
                    </button>
                )}
            </div>
            <div className="items-center flex flex-col justify-center w-full  flex-1">
                {onboardingSlides[currentSlideIndex].children}
            </div>
            <div className=" w-full  text-center ">
                <h1 className="pb-2">
                    {onboardingSlides[currentSlideIndex].header}
                </h1>
                <p>{onboardingSlides[currentSlideIndex].description}</p>
            </div>
            <div className=" text-center w-full  items-center">
                <div className="flex justify-center my-6 ">
                    {onboardingSlides.map((_, index) => (
                        <span
                            key={_.header}
                            // eslint-disable-next-line prettier/prettier
                            className={`w-3 h-3 mx-1 rounded-full 
                                ${index <= currentSlideIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
                        />
                    ))}
                </div>

                <Button
                    data-testid="onboarding-next-btn"
                    disabled={isDisabled(currentSlideIndex)}
                    onClick={onNextHandler}
                >
                    {ActionButtonText}
                </Button>
            </div>
        </div>
    );
}

export function Accordion(props: {
    selectedLocations: string[];
    setSelectedLocations: React.Dispatch<React.SetStateAction<string[]>>
}) {
    const { selectedLocations, setSelectedLocations } = props
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    async function onToggleLocationsHandler(id: string) {
        if (selectedLocations.includes(id)) {
            const newSelectedInterests = selectedLocations.filter(
                (item) => item !== id
            );
            setSelectedLocations(newSelectedInterests);
        } else {
            const newSelectedInterests = [...selectedLocations, id];
            setSelectedLocations(newSelectedInterests);
        }
    }

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden">
            {regions.map((region, index) => (
                <div key={region.name} className="border-b">
                    <button
                        onClick={() => toggleAccordion(index)}
                        className="w-full flex justify-between items-center p-4 text-left text-gray-700 font-medium hover:bg-gray-100 focus:outline-none focus:ring focus:ring-secondary"
                    >
                        <span>{region.name} <span className='text-action'>{region.locations.filter(location => selectedLocations.includes(location.id)).length}</span></span>
                        <svg
                            className={`w-5 h-5 transform transition-transform ${activeIndex === index
                                ? 'rotate-180'
                                : 'rotate-0'
                                }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </button>
                    {activeIndex === index && (
                        <div className="p-4 text-gray-600 bg-gray-50">
                            <div className="pb-2 pr-4">
                                {
                                    region.locations.map(location =>
                                        <div className='pb-2' key={location.id}>
                                            <Pill
                                                isSelected={selectedLocations.includes(location.id)}
                                                title={location.region}
                                                onClick={() => onToggleLocationsHandler(location.id)}
                                            />
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default OnboardPage;
