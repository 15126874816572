import React from 'react';

export function Button(
    props: React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    >
) {
    const { type = 'button', disabled } = props;
    return (
        <button
            type={type}
            className={`${disabled ? 'bg-gray-200 ' : 'bg-action hover:bg-action-hover'} text-white p-2 rounded-lg text-lg font-semibold w-full
                active:bg-action-hover active:shadow-lg  
                `}
            {...props}
        />
    );
}

export function Input(
    props: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >
) {
    return (
        <input
            className="bg-white border border-gray-300 p-2 rounded-lg w-full active:border-2 active:border-button-blue "
            {...props}
        />
    );
}

export function Page(
    props: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
) {
    return <div className="p-4 h-full w-full " {...props} />;
}
