import React, { useEffect, useState } from 'react';
import { captureException, withScope } from '@sentry/react';
import { useAuth } from '../../contexts/AuthContext.tsx';

import { Button, Page } from '../../components/common.tsx';
import { setUserDataLoactions } from '../../queries/users.query.ts';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { Accordion } from './OnboardPage.tsx';

export function LocationPage() {
    const { getLocations, userData, fetchUserData } = useAuth();
    const [selectedLocations, setSelectedLocations] =
        useState<string[]>(getLocations());

    const [successMesage, setSuccessMessage] = useState<null | string>(null);

    useEffect(() => {
        setSelectedLocations(getLocations());
    }, [userData]);

    const [loading, setLoading] = useState(false);
    async function onSaveTestDate() {
        setLoading(true);
        try {
            if (userData) {
                await setUserDataLoactions(userData.id, selectedLocations);
                setSuccessMessage('Driving Test Locations Updated');
                await fetchUserData();
            }
        } catch (e) {
            withScope((scope) => {
                scope.setExtra('uid', userData?.id);
                scope.setExtra('action', 'dashboard_save_date');
                captureException(e);
            });
        }
        setLoading(false);
    }
    return (
        <Page>
            <LoadingOverlay isLoading={loading} />
            <h1 className="pb-2">Locations</h1>
            <p className="pb-2">
                Keeping locations up to date will make sure you only receive
                driving test availabilty dates that are close to you.
            </p>
            <div>
                <div className="pb-2 w-full h-full flex items-start">
                    <Accordion
                        selectedLocations={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                    />
                </div>
                <div className="pt-4">
                    <Button onClick={() => onSaveTestDate()}>Save</Button>
                </div>
            </div>

            <p className="text-green-600 pt-4 text-xl">{successMesage}</p>
        </Page>
    );
}
