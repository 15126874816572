/* eslint-disable import/prefer-default-export */
import {
    arrayRemove,
    arrayUnion,
    doc,
    getDoc,
    setDoc,
    updateDoc,
} from 'firebase/firestore';
import { collections } from './types.query.ts';
import auth, { db } from '../firebase/config.firbase.ts';

export type UserData = {
    id: string;
    isOnboarded: boolean;
    centres: string[];
    plan?: 'early_access' | 'basic' | 'premium';
    currentBookingDate?: number;
    agreeToTerms: boolean;
    email: string;
};

export async function createUserData(
    uid: string,
    email: string
): Promise<void> {
    return setDoc(doc(db, collections.users, uid), {
        isOnboarded: false,
        centres: [],
        agreeToTerms: true,
        email,
    });
}

export async function setUserDataLoactions(
    uid: string,
    locations: string[]
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        centres: locations,
    });
}

export async function setUserDataBookingDate(
    uid: string,
    date: number
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        currentBookingDate: date,
    });
}

export async function setUserDataInterests(
    uid: string,
    interests: number[]
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        interests,
    });
}

export async function setUserDataHasOnboarded(uid: string): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        isOnboarded: true,
    });
}

export async function setUserDataHasSubscribed(uid: string): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        plan: 'early_access',
    });
}

export async function getUserData(): Promise<UserData | undefined> {
    if (auth.currentUser) {
        const userDocRef = doc(db, collections.users, auth.currentUser.uid);
        const userData = await getDoc(userDocRef);
        return {
            id: userData.id,
            ...userData.data(),
        } as UserData;
    }
    return undefined;
}

export async function addContentToFavourites(
    uid: string,
    contentId: number
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        favouritedContent: arrayUnion(contentId),
    });
}

export async function removeContentToFavourites(
    uid: string,
    contentId: number
): Promise<void> {
    return updateDoc(doc(db, collections.users, uid), {
        favouritedContent: arrayRemove(contentId),
    });
}
