import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { captureException, withScope } from '@sentry/react';
import { Button, Input } from '../../components/common.tsx';
import LoadingOverlay from '../../components/LoadingOverlay.tsx';
import { routeNames } from '../../navigation/types.navigation.ts';
import { UnAuthSidePanel } from './SignupPage.tsx';

export function toLoginLinkHandler(email: string): string {
    const searchParams = new URL(
        `${window.location.origin}${routeNames.login}`
    );
    searchParams.searchParams.append('email', email);
    return searchParams.href;
}

function ForgotPasswordPage() {
    const auth = getAuth();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const onResetPassword = async () => {
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
            // Send reset password email
            setSuccess(true);
        } catch (e) {
            setError('Failed to reset password');
            withScope((scope) => {
                scope.setExtra('uid', 'anon');
                scope.setExtra('action', 'anon_change_password');
                captureException(e);
            });
        }
        setLoading(false);
    };

    return (
        <div className=" h-full w-full flex">
            <LoadingOverlay isLoading={loading} />
            <div className="md:w-5/12 w-full bg-gray-100  p-3 h-full">
                <div className="m-auto h-full bg-white rounded-lg shadow-lg p-4 ">
                    <Link to={toLoginLinkHandler(email)} className="mr-2">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="text-2xl"
                        />
                    </Link>
                    <div className="flex h-full justify-center items-center text-center ">
                        <div>
                            <h1 className="text-3xl mb-6">Forgot Password</h1>
                            <div className="pt-2">
                                <p>
                                    Enter your email address to reset your
                                    password.
                                </p>
                            </div>
                            <div className="pt-2">
                                {error && (
                                    <p className="text-red-600">{error}</p>
                                )}

                                {success && (
                                    <p className="text-green-600">
                                        Password reset email sent
                                    </p>
                                )}
                            </div>
                            <div className="pt-2">
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="pt-4">
                                <Button onClick={onResetPassword}>
                                    Reset Password
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <UnAuthSidePanel
                text1="Lost your password?"
                text2="Don't worry it happens to everyone"
                text3=""
            />
        </div>
    );
}

export default ForgotPasswordPage;
