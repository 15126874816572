import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { captureException, withScope } from '@sentry/react';
// import { useAuth } from '../../contexts/AuthContext.tsx';
// import { setUserDataHasSubscribed } from '../../queries/users.query.ts';
// import { routeNames } from '../../navigation/types.navigation.ts';
import { UnAuthSidePanel } from '../../pages/unauthenticated/SignupPage.tsx';
// import PayPalSubscriptionButton from '../components/PayButton.tsx';
import { Button } from '../../components/common.tsx';

export function SubscriptionPage() {
    // const navigate = useNavigate();

    // const { user, userData } = useAuth();

    // const handlePaymentSuccess = async () => {
    //     try {
    //         await setUserDataHasSubscribed(user?.uid || '');

    //         if (userData?.isOnboarded) {
    //             navigate(routeNames.dashboard);
    //         } else {
    //             navigate(routeNames.onboard);
    //         }
    //     } catch (e) {
    //         withScope((scope) => {
    //             scope.setExtra('uid', user?.uid);
    //             scope.setExtra('action', 'change_password');
    //             captureException(e);
    //         });
    //     }
    // };

    return (
        <div className="flex h-full   ">
            <div className="md:w-5/12 w-full bg-gray-100  p-3 h-full ">
                <div className=" w-full bg-white rounded-lg shadow-lg p-4  h-full flex items-center justify-center pt-0">
                    <div>
                        <p className="text-9xl text-center">🏎️</p>

                        <h1 className="text-3xl font-bold mb-2 text-center">
                            Get Access to EarlyDrive for $4.99
                        </h1>
                        <p className="text-gray-500 text-center pb-4">
                            Start receiving Notification Instantly
                        </p>

                        <div className="bg-blue-50 p-4 rounded-md mb-6">
                            <h2 className="text-xl font-semibold text-blue-900">
                                Why Purchase?
                            </h2>
                            <ul className="list-disc pl-5 mt-2 text-blue-800">
                                <li>
                                    Be Notified when test date become available
                                </li>
                                <li>Stay On Top of Multiple Centres</li>
                                <li>Cut your wait time by weeks</li>
                            </ul>
                        </div>
                        <Button
                            onClick={() => {
                                window.location.href =
                                    'https://buy.stripe.com/28o7vfgIubWg7285kk';
                            }}
                        >
                            Buy
                        </Button>
                        {/* <PayPalSubscriptionButton
                            onSuccess={handlePaymentSuccess}
                        /> */}
                        {/* <p className="text-sm text-gray-500 mt-3 text-center">
                            By purchasing, you agree to our{' '}
                            <a
                                href="/terms"
                                className="text-blue-500 hover:underline"
                            >
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="/privacy"
                                className="text-blue-500 hover:underline"
                            >
                                Privacy Policy
                            </a>
                            .
                        </p> */}
                        <p className="text-sm text-gray-500 mt-3 text-center">
                            If you have any issues please contact
                            support@early-drive.com
                        </p>

                        <p className="text-sm text-gray-500 mt-3 text-center">
                            Paypal Payment Options not being displayed? Please
                            Refresh page.
                        </p>
                    </div>
                </div>
            </div>
            <UnAuthSidePanel
                text1={`You're so close!`}
                text2="Purchase now to start getting notified about available tests"
                text3=""
            />
        </div>
    );
}
