/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-shadow
export enum routeNames {
    login = '/login',
    terms = '/terms',
    forgot = '/forgot',
    signup = '/signup',
    dashboard = '/dashboard',
    account = '/account',
    settings = 'settings',
    contact = 'contact',
    onboard = '/onboard',
    subscription = '/subscription',
    location = '/location',
    account_settings_password = `${routeNames.account}/${settings}/password`,
    account_settings = `${routeNames.account}/${settings}`,
    account_contact = `${routeNames.account}/${contact}`,
}
